@import "~@/assets/scss/abstracts.scss";
.gachi-slider {
    /*width: 1000px;*/
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden
}

.gachi-slider .swiper-pagination-bullets {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: .3s opacity;
    z-index: 10;
}

.gachi-slider .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    background-color: #fff;
    opacity: .6;
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

.gachi-slider .swiper-pagination-bullet-active {
    background-color: #ff7300;
}

.gachi-slider .swiper-button-prev {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 50px;
    height: 50px;
    background-color: #ff7300;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gachi-slider .swiper-button-next {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 50px;
    height: 50px;
    background-color: #ff7300;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gachi-slider .swiper-button-next .icon-arrow, .swiper-button-prev .icon-arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/common/img/Arrow.svg) no-repeat 50% 50%
}

.gachi-slider .swiper-button-next .icon-arrow {
    transform: rotate(-180deg);
}

.gachi-slider .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 25px
}

.gachi-slider .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 25px
}

.gachi-slider .swiper-button-next:after, .swiper-button-prev:after {
    display: none
}

.gachi-slider input[type=radio] {
    display: none
}

.slide-img {
    width: 100%;
    height: 100%
}

ul.slide-ul {
    padding: 0;
    margin: 0;
    height: 400px;
    width: calc(100% * 4);
    display: flex;
    transition: 1s;
    list-style: none
}

ul.slide-ul li {
    width: calc(100% / 4);
    height: 400px;
    padding: 0;
    margin: 0;
    list-style: none
}

.slide-bullet {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    z-index: 2
}

.gachi-slider input[type=radio]:nth-child(1):checked ~ .slide-bullet > label:nth-child(1) {
    background-color: #921919
}

.gachi-slider input[type=radio]:nth-child(2):checked ~ .slide-bullet > label:nth-child(2) {
    background-color: #921919
}

.gachi-slider input[type=radio]:nth-child(3):checked ~ .slide-bullet > label:nth-child(3) {
    background-color: #921919
}

.gachi-slider input[type=radio]:nth-child(4):checked ~ .slide-bullet > label:nth-child(4) {
    background-color: #921919
}

.gachi-slider input[type=radio]:nth-child(1):checked ~ ul.slide-ul {
    margin-left: 0
}

.gachi-slider input[type=radio]:nth-child(2):checked ~ ul.slide-ul {
    margin-left: -100%
}

.gachi-slider input[type=radio]:nth-child(3):checked ~ ul.slide-ul {
    margin-left: -200%
}

.gachi-slider input[type=radio]:nth-child(4):checked ~ ul.slide-ul {
    margin-left: -300%
}